<template>
  <h1
    class="text-2xl font-bold text-black-700 text-center w-full pb-4 pt-2 dark:text-white"
  >
  Add {{this.$props.album}}
  </h1>
  <div
     class="w-full mb-2 h-64 rounded bg-lable-color max-h-80 border-2 border-gray-300 border-dashed flex flex-col justify-center items-center cursor-pointer hover:border-gray-500"
    :class="[
      fileHovering
        ? 'animate-pulse bg-gray-100 border-gray-200 transition-all dark:bg-gray-800'
        : '',
    ]"
    @drop.stop.prevent="dragFileChange"
    @dragenter.prevent="fileHovering = true"
    @dragleave="fileHovering = false"
    @dragover.prevent
  >
    <div v-if="loading">
      <loading-spinner class="w-full flex-col items-center" />
    </div>
    <div v-else>
      <label
        class="dark:bg-primaryDark flex items-center justify-center w-full h-64 px-4 transition rounded appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
      >
        <span
          class="flex items-center space-x-2 flex-col gap-2"
          @dragover.prevent
          @drop="onDrop"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-10 h-10 text-dropzone-color dark:text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            />
          </svg>
          <span
            class="font-normal text-sm dark:text-white text-black leading-6 text-gray-500"
            accept="image/x-png,image/gif,image/jpeg"
            @change="$refs.fileInput.click()"
          >
            <span class="font-semibold">Click to upload</span> or Drag and drop <br />
            SVG, PNG, JPG or GIF
          </span>
        </span>
        <input
          @change="fileChange($event.target.name, $event.target.files)"
          name="file-input"
          ref="file"
          type="file"
          class="hidden"
          multiple
        />
      </label>
    </div>
  </div>
  
  <button
    @click="$emit('onCancel')"
    class="inline-flex items-end px-6 py-2 border border-gray-200 text-base font-medium rounded-md shadow-sm text-gray-700 bg-gray-100 hover:bg-white float-right"
  >
    Close
  </button>
</template>

<script>
import { ref} from "vue";
import { useStore } from "vuex";
import LoadingSpinner from "@/components/widgets/LoadingSpinner";
import { AllowedImageFormats, ImageSizeLimit, PhotoAlbumNames } from "@/constants";
// import { TrashIcon } from "@heroicons/vue/outline/esm";
import { createToaster } from "@meforma/vue-toaster";

export default {
  name: "MediaImageDragAndDropField",
  components: { LoadingSpinner },
  props: {
    album: {
      type: String,
      default: "Photos",
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const fileHovering = ref(false);
    const loading = ref(false);
   let images = [];
 
    let profileLabel = "";
    if (props.album === "Photos") {
      profileLabel = PhotoAlbumNames.Photos;
    } else if (props.album === "Headshots") {
      profileLabel = PhotoAlbumNames.Headshots;
    } else {
      profileLabel = PhotoAlbumNames.ProfilePhotos;
    }
  store.dispatch("fetchUserAlbum", profileLabel)
   
    // Fetch profile pictures

  return {
      fileHovering,
      loading,
     fileChange(_, files) {
        loading.value = true; // Give the impression of work

        setTimeout(() => {
          loading.value = false;
        }, 1400);

        for (let i = 0; i < files.length; i++) {
          let format = files[i].name.split(".")[1];
          let fileToBig = files[i].size > ImageSizeLimit ? true : false;
          let formatAllowed = AllowedImageFormats.includes(format) ? true : false;

          images.push({
            file: files[i],
            preview: URL.createObjectURL(files[i]),
            title: "",
            photographer: "",
            caption: "",
            location: "",
            date: "",
            album: profileLabel,
            meta: {
              fileToBig,
              formatAllowed,
              hasError: fileToBig || !formatAllowed,
            },
          });
        }

        emit("onChange", images);
        images = [];
      },
      dragFileChange(e) {
        let files = e.dataTransfer.files;
        this.fileChange(null, files);
      },
      async deleteEntry(uuid) {
        const toast = createToaster({ position: "top-right" });
        const result = confirm("Are you sure you want to delete?");
        try {
          if (uuid && result) {
            await store.dispatch("editUserMediaImage", {
              uuid,
              delete: true,
              logo: false,
            });
            toast.success(`Image has been deleted successfully`);
          }
         store.dispatch("fetchUserAlbum", profileLabel) // Fetch profile pictures
          emit("onCancel")
        } catch (e) {
          console.error(e);
        }
      },
    };
  },
};
</script>
